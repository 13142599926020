<header>
  <slot />
</header>

<style>
  header {
    margin: 8px auto;

    font-size: 24px;
    font-weight: bold;
  }
</style>
